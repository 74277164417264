import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@material-ui/core";
import logo from "../../assets/png/logo.png";
import { Alert } from "@material-ui/lab";
import { actions } from "spe-databinder";
import { useDispatch } from "react-redux";
import { VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { Message } from "../../utils";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom";
import { Routes } from "../../router/routes";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { withAllContexts } from "../../HOCs";
import { config } from "../../config";

const inputStyle = { WebkitBoxShadow: "0 0 0 1000px white inset" };
const useStyles = makeStyles((theme) => {
  return {
    layoutgrid: {
      height: "100vh",
      backgroundColor: theme?.palette?.background?.divbackground,
      padding: "50px 60px",
      [theme.breakpoints.down("lg")]: {
        padding: "25px 60px",
      },
      [theme.breakpoints.only("xl")]: {
        padding: "50px 60px",
      },
      display: "flex",
      alignItems: "center",
    },
    outerpaper: {
      backgroundColor: theme?.palette?.background?.paper,
      borderRadius: "30px",
      padding: "50px",
      [theme.breakpoints.down("lg")]: {
        padding: "25px",
      },
      [theme.breakpoints.only("xl")]: {
        padding: "50px",
      },
      position: "relative",
    },
    loginimagecss: {
      width: "100%",
      height: "auto",
      [theme.breakpoints.only("lg")]: {
        width: "340px",
        height: "429px",
      },
      [theme.breakpoints.only("xl")]: {
        width: "730px",
        height: "580px",
      },
    },
    logintext: {
      [theme.breakpoints.only("lg")]: {
        fontSize: "30px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "40px",
      },
      fontfamily: "Poppins_bold !important",
      lineHeight: "1",
    },
    credentailstext: {
      fontfamily: "Poppins_medium ",
      color: "#CDCED0",
    },
    forgotpassword: {
      color: theme?.palette?.othertextcolor?.forgotpasswordtext,
      [theme.breakpoints.only("lg")]: {
        fontSize: "13px",
      },
      [theme.breakpoints.only("xl")]: {
        fontSize: "18px",
      },
      cursor: "pointer",
    },
    button: {
      borderRadius: "50px",
    },
    form: {
      "& .MuiOutlinedInput-root": {
        borderRadius: "50px !important",
      },
      "& .MuiOutlinedInput-input": {
        padding: "9px 14px",
      },
    },
    textfield: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
      "&.Mui-focused fieldset": {
        borderColor: "green",
      },
    },
    logo: {
      position: "absolute",
      top: "20px",
      left: "20px",
      width: "78px",
      height: "69px",
    },
  };
});

function Login(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    userid: "",
    password: "",
    error: {
      error: false,
      useriderror: false,
      passworderror: false,
    },
    showPassword: false,
  });
  const [rememberme, setRememberme] = useState(false);
  const [alert, setAlert] = useState({
    alertbool: false,
    alertmsg: "",
  });
  // React.useEffect(() => {}, []);

  const validations = () => {
  
    let errordata = {
      error: false,
      useriderror: false,
      passworderror: false,
    };
    ["userid", "password"].forEach((e) => {
     
      if (state[e]?.length === 0) {
        errordata = {
          ...errordata,
          error: true,
          [e + "error"]: true,
        };
      }
    });
    setState({ ...state, error: { ...state.error, ...errordata } });
    return errordata.error;
  };
  const handlesubmit = async (e) => {
    
    e.preventDefault();
    if (validations()) {
      setAlert({
        ...alert,
        alertmsg: Message.requiredMessage,
        alertbool: true,
      });
      return;
    } else {
      setAlert({
        ...alert,
        alertmsg: "",
        alertbool: false,
      });
    }

    let login_payload = {
      username: state.userid,
      password: state.password,
    };
    // const keycloackresult = await dispatch(
    //   actions.SPE_LOGIN({ login_payload })
    // );
    const keycloackresult = await axios({
      method: "post",
      url: config.login,
      data: login_payload,
    })
    let keycloackdata = keycloackresult?.data;

    if ((keycloackdata?.statusCode) + "" === "201") {
      props.backDrop.setBackDrop({
        open: true,
        message: Message.logginginbackdrop,
      });

      let tokenDetails = jwt_decode(keycloackdata?.response?.token);
      let UserDetails = jwt_decode(keycloackdata?.response?.userinfo);
      
      
      // let tokenDetails = keycloackdata?.tokenDetails;
      let keyclockid = keycloackdata?.keyclkId;
      sessionStorage.setItem("tokenDetails", JSON.stringify({ tokenDetails }));
      sessionStorage.setItem(
        "access_token",
        tokenDetails.access_token
      );
      sessionStorage.setItem("keyclkId", keyclockid);

      // to get logined person details from idmperson

      // let personresult = await dispatch(
      //   actions.SPE_LOGIN_READ({ filterid: keyclockid })
      // );
      // let persondata = personresult?.payload?.data;
      let roll_id = UserDetails?.roleid;
      let roll_name = UserDetails?.rolename;


      sessionStorage.setItem("roll_id", roll_id);
      sessionStorage.setItem("roll_name", roll_name);

      // // to get rolldetails of a person
      // let getroll = await dispatch(
      //   actions.SPE_LOGIN_ROLL({ roll_id: roll_id })
      // );
      // let rollresult = getroll.payload.data;

      sessionStorage.setItem("permissionData", (keycloackdata?.response?.permissionData));
      props?.backDrop?.setBackDrop({
        open: false,
        message: "",
      });
      if (rememberme) {
        localStorage.setItem(
          "permsn_repo",
          sessionStorage.getItem("permsn_repo")
        );
        localStorage.setItem(
          "perrolepermsnid",
          sessionStorage.getItem("perrolepermsnid")
        );
        localStorage.setItem("roll_name", sessionStorage.getItem("roll_name"));
        localStorage.setItem("roll_id", sessionStorage.getItem("roll_id"));
        localStorage.setItem("keyclkId", sessionStorage.getItem("keyclkId"));
        localStorage.setItem(
          "access_token",
          sessionStorage.getItem("access_token")
        );
        localStorage.setItem(
          "permissionData",
          sessionStorage.getItem("permissionData")
        );
        localStorage.setItem(
          "tokenDetails",
          localStorage.getItem("tokenDetails")
        );
      }
      history.push(Routes.home);
    } else if (keycloackdata?.Code === "1111") {
      setAlert({
        ...alert,
        alertmsg: keycloackdata?.errormsg?.error_description,
        alertbool: true,
      });
    } else {
      setAlert({
        ...alert,
        alertmsg: Message.somethingwentwrong,
        alertbool: true,
      });
      history.push(Routes.home);
    }
  };
  const handleClickShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <div className={classes.layoutgrid}>
      <Grid className={classes.outerpaper} container>
        <img 
        // src={logo} 
        src = "images/ainqa logo.png"
        alt="logo" className={classes.logo} />
        <Grid item lg={5} md={5} sm={12} xs={12}>
          <img
            src={process.env.REACT_APP_SPE_LOGO}
            alt="doctor"
            className={classes.loginimagecss}
          />
        </Grid>
        <Grid
          container
          item
          lg={7}
          md={7}
          sm={12}
          xs={12}
          alignItems="center"
          justify="center"
        >
          <Grid
            item
            container
            lg={7}
            md={6}
            sm={10}
            xs={10}
            direction="row"
            justify="flex-start"
            spacing={2}
          >
            {alert.alertbool && (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Alert
                  icon={false}
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setAlert({ ...alert, alertbool: false });
                      }}
                    >
                      <HighlightOffIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {alert.alertmsg}
                </Alert>
              </Grid>
            )}
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              <Typography
                variant="h5"
                // className={classes.logintext}
                color="textSecondary"
              >
                Login
              </Typography>
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              <Typography variant="h6" color="textPrimary">
                Enter your credentials to access your account
              </Typography>
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              <FormControl
                required
                fullWidth
                error={state.error.useriderror}
                className={classes.form}
              >
                <InputLabel
                  // className={classes.formlabel}
                  shrink="false"
                  htmlFor="component-outlined"
                >
                  User ID/Bed No
                </InputLabel>
                <OutlinedInput
                  inputProps={{ style: inputStyle }}
                  id="component-outlined"
                  fullWidth
                  variant="outlined"
                  type={"text"}
                  value={state.userid}
                  onChange={(e) => {
                    setState({ ...state, userid: e.target.value });
                  }}
                  error={state.error.useriderror}
                  className={classes.textfield}
                  // className={classes.inputClasses}
                />
              </FormControl>
            </Grid>
            <Grid item sm={12} xs={12} md={12} lg={12} xl={12}>
              <FormControl
                fullWidth
                required
                className={classes.form}
                error={state.error.passworderror}
              >
                <InputLabel
                  shrink="false"
                  htmlFor="standard-adornment-password"
                  // className={classes.formlabel}
                >
                  Password
                </InputLabel>
                <OutlinedInput
                  inputProps={{ style: inputStyle }}
                  fullWidth
                  className={classes.textfield}
                  type={state.showPassword ? "text" : "password"}
                  variant="outlined"
                  value={state.password}
                  onChange={(e) => {
                    setState({ ...state, password: e.target.value });
                  }}
                  error={state.error.passworderror}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {state.showPassword ? (
                          <VisibilityOutlined />
                        ) : (
                          <VisibilityOffOutlined />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid
              item
              container
              justify="space-around"
              alignItems="center"
              lg={12}
            >
              <Grid item>
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      color="primary"
                      className={classes.checkbox}
                      icon={<RadioButtonUncheckedIcon fontSize="small" />}
                      checkedIcon={<CheckCircleIcon fontSize="small" />}
                      onChange={() => setRememberme(true)}
                    />
                  }
                  label="Remember me"
                  labelPlacement="end"
                  className={classes.checkbox}
                />
              </Grid>
              <Grid item>
                <Typography className={classes.forgotpassword}>
                  Forgot password?
                </Typography>
              </Grid>
            </Grid>
            <Grid item lg={12} sm={12} xs={12} md={12} xl={12}>
              <Button
                fullWidth
                variant="contained"
                className={classes.button}
                onClick={handlesubmit}
                color="primary"
              >
                Log In
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default withAllContexts(Login);
