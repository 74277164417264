import React from "react";
import Header from "./topNavbar";

class TopNavbarParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <Header />;
  }
}

export default TopNavbarParent;
