import dotenv from "dotenv";
dotenv.config();

let config = {};

config.keycloakUrl = process.env.REACT_APP_KEY_CLOACK_URL;
config.fileUploadFullUrl = `${process.env.REACT_APP_FILEUPLOAD_URL}/${process.env.REACT_APP_DB}/`;
config.loginimageurl = `${process.env.REACT_APP_FILEUPLOAD_URL}/${process.env.REACT_APP_DB}/${process.env.REACT_APP_LOGIN_IMAGE_FILE_ID}`;
config.impourl = process.env.REACT_APP_IMPO_URL;
config.login = `${process.env.REACT_APP_IDM_SERVICES_URL}/PractitionerLogin`;

export { config };
