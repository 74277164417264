import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  styled,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  Autocomplete,
  ToggleButton,
  ToggleButtonGroup,
} from "@material-ui/lab";
import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { actions } from "spe-databinder";
import { AlertContext, DialogContext, BackdropContext } from "../../contexts";
import { Message } from "../../utils";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  special: {
    width: "300px",
  },
  internal: {
    display: "flex",
    justifyContent: "center",
  },
  toggle: {
    textTransform: "capitalize",
    borderRadius: "5px",
    width: "120px",
  },
  join: {
    textTransform: "capitalize",
  },
}));

const ToggleButtons = styled(ToggleButton)({
  color: "#1671D9",
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: "#1671D9",
  },
});

function AvcDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dialog = useContext(DialogContext);

  const alert = useContext(AlertContext);
  const [avcdata, setAvcdata] = React.useState({
    purpose: [],
    qrcode: "",
    moderateurl: "",
    alignment: null,
    externalemail: "",
    loading: false,
  });

  const [error, setError] = React.useState({
    error: false,
    errormsg: "",
  });
  const handleChange = (event, newAlignment) => {
    setAvcdata((prevState) => ({
      ...prevState,
      alignment: newAlignment,
    }));
  };
  const specialist = [
    { name: "Dr Chan", email: "test@ainqa.com" },
    { name: "Dr.Tan", email: "test@ainqa.com" },
    { name: "Yuwaraj Kumar", email: "yuwaraj.balakrishnan@ainqa.com" },
    { name: "Mohd Fikri", email: "fikri.bakri@ainqa.com" },
    { name: "Deepan", email: "deepanraj.gopal@ainqa.com" },
  ];
  const onHandleChangeSpecialist = (name, value) => {
    setAvcdata((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const getpurpose = async () => {
    let res = await dispatch(actions.SPE_GET_PURPOSE());
    let data = res?.payload?.data.map((e) => e?.coding[0]?.display);
    setAvcdata((prevState) => ({
      ...prevState,
      purpose: data,
    }));
  };
  useEffect(() => {
    getpurpose();
  }, []);
  useEffect(() => {
    dialog.setDialog({
      ...dialog,
      onOk: SubmitClk,
    });
  }, [avcdata]);
  const onchangeEmail = (e) => {
    setAvcdata((prevState) => ({
      ...prevState,
      externalemail: e.target.value,
    }));
  };
  const SubmitClk = async (e) => {
    e.preventDefault();
    debugger;
    if (avcdata?.specialist?.name?.length > 0) {
      setAvcdata((prevState) => ({
        ...prevState,
        loading: true,
      }));

      setError({ ...error, error: false, errormsg: "" });
      let avcdoctor = {
        name:
          avcdata.alignment === "internal"
            ? "Participant"
            : avcdata.alignment === "external"
            ? "Participant"
            : avcdata.specialist.name,
        email:
          avcdata.alignment === "internal"
            ? [avcdata.specialist.email, avcdata.internelspecialist.email]
            : avcdata.alignment === "external"
            ? [avcdata.specialist.email, avcdata.externalemail]
            : avcdata.specialist.email,
      };
      let res = await dispatch(actions.AVC_GET_CONFERENCE(avcdoctor));
      if (res?.payload?.error) {
        setAvcdata((prevState) => ({
          ...prevState,
          loading: false,
        }));
        alert.setSnack({
          ...alert,
          open: true,
          msg: res?.payload?.message,
          severity: "error",
        });
      } else {
        dialog.setDialog({
          ...dialog,
          negativeBtnDontShow: false,
          positiveBtnDontShow: false,
          closeButton: true,
        });
        setAvcdata((prevState) => ({
          ...prevState,
          qrcode: res?.payload?.data?.moderatorQRCode,
          moderateurl: res?.payload?.data?.moderatorUrl,
          loading: false,
        }));
      }
    } else {
      setError({ ...error, error: true, errormsg: Message.requiredMessage });
    }
  };
  const joinclick = () => {
    props.onclickJoin(avcdata.moderateurl);
    dialog.setDialog({
      ...dialog,
      open: false,
    });
  };
  return (
    <Grid container spacing={2} justify="center" direction="column">
      {!avcdata.qrcode ? (
        <>
          <Backdrop className={classes.backdrop} open={avcdata.loading}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="center"
            >
              <Grid item>
                <CircularProgress color="inherit" />
              </Grid>
              <Grid item>
                <Typography variant="h6" color="inherit">
                  {Message.conference}
                </Typography>
              </Grid>
            </Grid>
          </Backdrop>
          <Grid item>
            <Autocomplete
              size="small"
              value={avcdata.specialist}
              onChange={(e, v) => onHandleChangeSpecialist("specialist", v)}
              id="combo-box-demo"
              options={specialist}
              getOptionLabel={(option) => option.name}
              className={classes.special}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Specialist"
                  variant="outlined"
                  required={true}
                  helperText={error.errormsg}
                  error={error.error}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              size="small"
              id="combo-box-demo"
              options={avcdata.purpose}
              getOptionLabel={(option) => option}
              className={classes.special}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Purpose of vist"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item className={classes.internal}>
            <ToggleButtonGroup
              value={avcdata.alignment}
              exclusive
              size="small"
              onChange={handleChange}
              aria-label="Platform"
            >
              <ToggleButtons value="external" className={classes.toggle}>
                external
              </ToggleButtons>
              <ToggleButtons value="internal" className={classes.toggle}>
                internal
              </ToggleButtons>
            </ToggleButtonGroup>
          </Grid>
          {avcdata.alignment === "external" && (
            <Grid item>
              <TextField
                onChange={onchangeEmail}
                size="small"
                className={classes.special}
                id="outlined-basic"
                label="Email Address"
                variant="outlined"
              />
            </Grid>
          )}
          {avcdata.alignment === "internal" && (
            <Grid item>
              <Autocomplete
                size="small"
                value={avcdata.internelspecialist}
                onChange={(e, v) =>
                  onHandleChangeSpecialist("internelspecialist", v)
                }
                id="combo-box-demo"
                options={specialist}
                getOptionLabel={(option) => option.name}
                className={classes.special}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Internel Specialist"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          )}
          {/* <Grid item style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                        onClick={SubmitClk}
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{
                            textTransform: "capitalize",
                        }}
                    >
                        Submit
                    </Button>
                </Grid> */}
        </>
      ) : (
        <>
          <Grid item>
            <Box
              className={classes.special}
              component="img"
              src={"data:image/png;base64," + avcdata.qrcode}
            ></Box>
          </Grid>
          <Grid item className={classes.internal}>
            <Button
              variant="contained"
              color="primary"
              onClick={joinclick}
              className={classes.join}
            >
              Join
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
}
export default AvcDialog;
