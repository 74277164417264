import React, { useEffect, useState } from "react";
import { LinearProgress } from "@material-ui/core";
import networkCall from "./networkcall";
import {
  LocalStorageKeys,
  NetWorkCallMethods,
  refreshCacheAndReload,
  semverGreaterThan,
} from "./utils";


function AppAuth(props) {
  const [state, setState] = useState({
    loading: "",
  });
  useEffect(() => {
    checkForLatestBuild();
    refreshAPI();
  }, []);

  const refreshAPI = () => {
    let permsn_repo = localStorage.getItem("permsn_repo");
    let perrolepermsnid = localStorage.getItem("perrolepermsnid");
    let roll_name = localStorage.getItem("roll_name");
    let roll_id = localStorage.getItem("roll_id");
    let keyclkId = localStorage.getItem("keyclkId");
    let access_token = localStorage.getItem("access_token");
    let tokenDetails = localStorage.getItem("tokenDetails");
    if (
      permsn_repo &&
      perrolepermsnid &&
      roll_name &&
      roll_id &&
      keyclkId &&
      access_token &&
      tokenDetails
    ) {
      sessionStorage.setItem("permsn_repo", permsn_repo);
      sessionStorage.setItem("perrolepermsnid", perrolepermsnid);
      sessionStorage.setItem("roll_name", roll_name);
      sessionStorage.setItem("roll_id", roll_id);
      sessionStorage.setItem("keyclkId", keyclkId);
      sessionStorage.setItem("access_token", access_token);
      sessionStorage.setItem("tokenDetails", tokenDetails);
      // window.open(Routes.home, "_self");
      // window.location.replace(Routes.home);
    }
  };

  const checkForLatestBuild = () => {
    networkCall(
      `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? ":" + window.location.port : ""
      }/meta.json`,
      NetWorkCallMethods.get,
      null,
      null,
      false,
      true
    )
      .then((_) => {
        const isVersion = semverGreaterThan(
          _.data.version,
          sessionStorage.getItem(LocalStorageKeys.version)
        );
        sessionStorage.setItem(LocalStorageKeys.version, _.data.version);
        if (isVersion) {
          refreshCacheAndReload();
        }
      })
      .catch((err) => {
        console.log("err:", err);
      });
  };

  return <>{state?.loading ? <LinearProgress /> : props.children}</>;
}

export default AppAuth;
