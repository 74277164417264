import { Box, Grid, IconButton, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cardcarousels from "../../components/cardcarousels";
import Carousels from "../../components/carousel";
import { withNavBars } from "../../HOCs";
import { actions } from "spe-databinder";
// import { joiner } from "../../utils";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { checkWithIdm } from "../../function/checkwithidm";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slideleft from "../../assets/png/left.png";
import slideright from "../../assets/png/right.png";
import {checkWithCasbin} from "../../function/permissionHandling"

const useStyles = makeStyles((theme) => ({
  outerdiv: {
    // display: "grid",
    // gridTemplateRows: "0.9fr 1fr",
    // gridTemplateColumns: "1fr",
    // border: "5px solid red",
    padding: "1em ",
    [theme.breakpoints.only("xl")]: {
      gap: "20px",
      // padding: "30px",
    },
    [theme.breakpoints.down("lg")]: {
      gap: "10px",
      // padding: "15px",
    },
  },
  cardcontainer: {
    maxWidth: "100%",
    marginTop: "10px",
    [theme.breakpoints.only("xs")]: {
      justify: "center"
    },
  },
  cards: {
    // overflow: "hidden",
    cursor: "pointer",
    transition: "all 250ms ease-in-out",
  },
  add: {
    padding:"20px 20px 0px 20px "
    // height: "50%",
    // border: "2px solid red",
  },
}));
const settings = {
  dots: false,
  slidesToShow: 5,
  slidesToScroll: 1,
  swipeToSlide: true,
  infinite: false,
  prevArrow: <SamplePrevArrow />,
  nextArrow: <SampleNextArrow />,
  responsive: [
    ////////////////////res
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
        swipeToSlide: true,
      },
    },
    {
      breakpoint:1280,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
        swipeToSlide: true,
      },
    },
    {
      breakpoint:1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
        swipeToSlide: true,
      },
    },
    {
      breakpoint:820,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        swipeToSlide: true,
      },
    },
    {
      breakpoint: 585,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
      },
    },
  ],
};
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <Box component="img" src={slideright}></Box>
    </div>
  );
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <Box component="img" src={slideleft} style={{ marginLeft: "14px" }}></Box>
    </div>
  );
}
function Home() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selector = useSelector((state) => ({
    add: state?.SPEHOMESLICE?.spe_get_advertisement?.data ?? [],
    permissionroll: state?.speLauncherSlice?.spe_login_roll?.data ?? [],
    appcategory: state?.SPEHOMESLICE?.spe_get_applicationcategory?.data ?? [],
  }));

  const [state, setState] = useState([]);
  const [permission, setPermissiom] = useState([]);
  async function apis() {
    await dispatch(actions.SPE_GET_ADVERTISEMENT());
    await dispatch(actions.SPE_GET_APPLICATIONCATEGORY());
  }
  async function getPermission() {
    try {
      let permission_ = await checkWithCasbin(["Spe_Application"]);
      setPermissiom(permission_);
    } catch (err) {
      setPermissiom([]);
    }
  }

  React.useEffect(() => {
    setState(selector.appcategory ?? []);
  }, [selector.appcategory]);

  React.useEffect(() => {
    apis();
    getPermission();
  }, []);
  // const settings = {
  //   dots: false,
  //   slidesToShow: 5,
  //   slidesToScroll: 3,
  //   infinite: false,
  //   prevArrow: <SamplePrevArrow sx={{height:"100px"}}  />,
  //   nextArrow: <SampleNextArrow />,
  // };
  // function SampleNextArrow(props) {
  //   const { className, style, onClick } = props;
  //   return (
  //     <div
  //       className={className}
  //       style={{
  //         ...style,
  //         background: "#00000029",
  //         display: "flex",
  //         justifyContent: "center",
  //         marginRight: "30px",
  //         borderRadius: "5px",
  //         padding: "25px 8px",
  //       }}
  //       onClick={onClick}
  //     />
  //   );
  // }
  const element = document.getElementById("outergrid");
  const handlescroolright = (val) => {
    debugger;
    let element = document?.getElementById("outergrid");
    let samplecard = document?.getElementById("card1");
    element?.scrollBy(val * samplecard?.offsetWidth + 50, 0);
  };
  return (
    <div className={classes.outerdiv}>
      <div className={classes.add}>
        <Carousels json={selector.add ?? []} />
      </div>

      {/* <IconButton
          style={{
            width: "34px",
            height: "78px",
            borderRadius: "2px",
            position: "absolute",
            left: "0",
            top: "50%",
            zIndex: "30",
          }}
          // disabled={
          //   element?.scrollWidth === element?.scrollWidth ? true : false
          // }
          onClick={() => handlescroolright(-1)}
        >
          <ArrowBackIosIcon
            style={{
              color:
                element?.scrollWidth === element?.scrollWidth
                  ? "grey"
                  : "black",
            }}
          />
        </IconButton> */}
      <Grid
        // container
        // spacing={2}
        // justify="center"
        className={classes.cardcontainer}
        // direction="column"
        // id="outergrid"
        // style={{ flexWrap: "nowrap" }}
      >
        <Slider {...settings}>
          {state
            ?.filter((v) =>
              permission?.write?.indexOf(v.name) > -1
              // checkWithCasbin([v.name])
              // checkWithIdm(
              //   permission[0]?.permission[0]?.permissionRepoAccess,
              //   "1",
              //   "unique_id",
              //   v?._id,
              //   "read"
              // )
            )
            ?.map((v, i) => {
              return (
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={6}
                  xs={12}
                  id={"card" + i}
                  key={`card${i}`}
                 
                >
                  <Cardcarousels
                    data={v}
                    permission={
                      permission[0]?.permission[0]?.permissionRepoAccess
                    }
                  />
                </Grid>
              );
            })}
        </Slider>
      </Grid>

      {/* <IconButton
          style={{
            width: "34px",
            height: "78px",
            borderRadius: "2px",
            position: "absolute",
            top: "50%",
            right: "0",
            zIndex: "30",
          }}
          // disabled={
          //   element?.scrollWidth === element?.scrollWidth ? true : false
          // }
          onClick={() => handlescroolright(1)}
        >
          <ArrowBackIosIcon
            style={{
              transform: "rotate(180deg)",
              color:
                element?.scrollWidth === element?.offsetWidth
                  ? "grey"
                  : "black",
            }}
          />
        </IconButton> */}
    </div>
  );
}

export default withNavBars(Home);
