import React, { useContext } from "react";
import { makeStyles, styled, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Button, Grid, useMediaQuery } from "@material-ui/core";
import { config } from "../../config";
import { useHistory } from "react-router-dom";
import { DialogContext } from "../../contexts";
import AvcDialog from "../avccomponents";
import { checkWithIdm } from "../../function/checkwithidm";
import { Routes } from "../../router/routes";
import expand from "../../assets/expand.svg";
import {checkWithCasbin} from "../../function/permissionHandling";
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const useStyles = (props) => {
  return makeStyles((theme) => ({
    root: {
      padding: "30px 30px 10px 30px",
      borderRadius: "20px",
      cursor: props !== 0 ? "pointer" : "default",
      height: "max-content",
      // minHeight: "max-content",
      width: "270px",
      boxShadow: "0px 3px 15px #00000029",
      [theme.breakpoints.only("xl")]: {
        width: "380px",
        
      },
    },
    media: {
      height: 0,
    },
    expand: {
      transform: "rotate(0deg)",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
      
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },

    card: {
      backgroundColor:
        theme?.palette?.background
          ?.downborder_color_and_cardcarousels_inside_card,
      borderRadius: "20px",
      padding: "65px",
      boxShadow: "none",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    typecontent: {
      placeSelf: "center",
    },
    cardCollapseList: {},
    button: {
      backgroundColor: theme?.palette?.background?.button,
      textAlign: "center",
      borderRadius: "8px",
      color: theme?.palette?.primary?.main,
      margin: "6px 0px",
      padding: "8px 0px",
      [theme.breakpoints.only("xl")]: {
        padding: "10px 0px",
        fontSize: "24px",
},
       
    },
    IconButtongrid: {
      placeSelf: "center",
      
    },
    grid: {
      display: "grid",
     
    },
    img: {
      [theme.breakpoints.only("xl")]: {
        width: "140px",
        height: "140px",
      },
      [theme.breakpoints.only("lg")]: {
        width: "71px",
        height: "71px",
      },
    },
    categoryname: {
      color: theme?.palette?.othertextcolor?.blacktext,
      paddingTop: "15px",
      fontSize: "18px",
      fontFamily:"poppins_medium",
      [theme.breakpoints.only("xl")]: {
        paddingTop: "40px",
        fontSize:"28px"
      },
    },
    expandicon: {
      height: "18px",
      width:"18px",
      [theme.breakpoints.only("xl")]: {
        width: "30px",
        height: "30px",
      },
    }
  }));
};
function CardCarousels(props) {
  const theme = useTheme();
  const xl = useMediaQuery(theme.breakpoints.only("xl"));
  const history = useHistory();
  const classes = useStyles(props?.data?.route_url?.length)();
  const [expanded, setExpanded] = React.useState(false);
  const [permission, setPermissiom] = React.useState([]);
  const dialog = useContext(DialogContext);
  React.useEffect(() => {
    getPermission();
  }, []);
  async function getPermission() {
    try {
      let permission_ = await checkWithCasbin(["Spe_Application",props?.data?.name]);
      console.log(permission_, props?.data?.name,"v");
      setPermissiom(permission_);
    } catch (err) {
      setPermissiom([]);
    }
  }
  const handleExpandClick = (e) => {
    e?.stopPropagation();
    setExpanded(!expanded);
  };
  const joinfun = (url) => {
    history.push({
      pathname: Routes.iframe,
      state: { iframe: url },
    });
  };
  const cardclick = (e) => {
    if (props?.data?.name === "Smart Assist") {
      dialog.setDialog({
        open: true,
        title: "Smart Assist",

        body: <AvcDialog onclickJoin={joinfun} dialog={dialog} />,
        negativeBtnDontShow: true,
        positiveBtnDontShow: true,
        closeButton: false,
      });
    } else if (props?.data?.route_url !== "") {
      history.push({
        pathname: props?.data?.route_url,
        state: props?.data,
      });
    } else {
      window.open(props?.data?.url, "_self");
    }
  };
  
  return (
    <Card className={classes.root} onClick={cardclick}>
      <Card className={classes.card}>
        <img
          alt={props?.data?.name ?? "alter"}
          src={config.fileUploadFullUrl + props?.data?.imageid}
          className={classes.img}
          height="80px"
          width="80px"
        />
      </Card>
      <div className={classes.grid}>
        <div className={classes.typecontent}>
          <Typography className={classes.categoryname}>
            {props?.data?.name}
          </Typography>
        </div>

        <div className={classes.cardCollapseList}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Grid container direction="column">
              {props?.data?.grps
                ?.filter((v) =>
                  // checkWithIdm(
                  //   props?.permission,
                  //   "1",
                  //   "unique_id",
                  //   v?._id,
                  //   "read"
                  // )
                  permission?.write?.indexOf(v.appname) > -1
                )
                .map((v, i) => {
                  return (
                    <Grid item lg={12} md={12} xs={12} key={i}>
                      <Button
                        fullWidth
                        className={classes.button}
                        onClick={() => window.open(v?.url, "_self")}
                      >
                        {v?.appname}
                      </Button>
                    </Grid>
                  );
                })}
            </Grid>
          </Collapse>
        </div>
        <div
          className={classes.IconButtongrid}
          style={{ visibility: props?.data?.down_icon ? "visible" : "hidden" }}
        >
          <IconButton
            size="small"
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <img className={classes.expandicon} src={expand}/>
          </IconButton>
        </div>
      </div>
    </Card>
  );
}

export default CardCarousels;
