import React from "react";
import PropTypes from "prop-types";
import {
  Avatar,
  Button,
  Card,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useHistory } from "react-router";

const styles = makeStyles((theme) => ({
  card: {
    position: "relative",
    width: "320px",
  },
  box: {
    height: "100px",
    backgroundColor: theme.palette.background.profiledialog,
    display: "flex",
    justifyContent: "center",
  },
  title: {
    fontSize: "20px",
    fontFamily: "Poppins_medium",
    color: theme?.palette?.othertextcolor?.proftitle,
    marginTop: "20px",
  },
  imag: {
    position: "absolute",
    top: "60px",
    display: "flex",
    justifyContent: "center",
    height: "70px",
    width: "70px",
  },
  bottombox: {
    backgroundColor: "white",
    padding: "20px",
  },
  boxcontent: {
    flexDirection: "column",
    textAlign: "center",
    marginTop: "20px",
  },
  boxalign: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  avatar: {
    backgroundColor: theme?.palette?.background?.paper,
    color: theme?.palette?.othertextcolor?.blacktext,
    border: "1px solid gray",
    margin: "10px 10px 5px 10px",
    fontSize: "17px",
  },
  boxx: {
    margin: "60px 0",
  },
  button: {
    backgroundColor: theme?.palette?.background?.profilebutton,
    color: theme?.palette?.othertextcolor?.profilebutton,
    width: "200px",
    height: "50px",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme?.palette?.background?.profilebutton,
      boxShadow: "none",
    },
  },
  name: {
    fontSize: "20px",
    fontFamily: "Poppins_medium",
  },
  email: {
    fontSize: "13px",
    fontFamily: "Poppins_medium",
    marginTop: "10px",
  },
  room: {
    fontSize: "13px",
    fontFamily: "Poppins_medium",
  },
}));
function Profiledialog(props) {
  const classes = styles();
  const history = useHistory();
  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    // localStorage.removeItem("RoleId")
    history.push("/");
  };
  return (
    <Card className={classes.card}>
      <Grid container spacing={0} direction="column">
        <Grid item className={classes.box}>
          <Typography className={classes.title}>
            {props.profdata.title}
          </Typography>
          <Avatar className={classes.imag}>
            {props?.profdata?.name[0] ?? ""}
          </Avatar>
        </Grid>
        <Grid item className={classes.bottombox}>
          <Grid container className={classes.boxcontent}>
            <Grid item>
              <Typography className={classes.name}>
                {props.profdata.name}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.email}>
                {props.profdata.email}
              </Typography>
            </Grid>
            <Grid item className={classes.boxalign}>
              <Grid>
                <Avatar variant="circular" className={classes.avatar}>
                  {props.profdata.roomNo}
                </Avatar>
                <Typography className={classes.room}>ROOM</Typography>
              </Grid>
              <Grid>
                <Avatar variant="circular" className={classes.avatar}>
                  {props.profdata.bedNo}
                </Avatar>
                <Typography className={classes.room}>BED</Typography>
              </Grid>
            </Grid>
            <Grid item className={classes.boxx}>
              <Button
                startIcon={<ExitToAppIcon />}
                variant="contained"
                className={classes.button}
                onClick={handleLogout}
              >
                LOG OUT
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}
Profiledialog.propTypes = {};
export default Profiledialog;
