import React from "react";
import { Grid, Typography, Box, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import withNavBars from "../HOCs/withNavBars";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { useHistory, useLocation } from "react-router-dom";
import { config } from "../config";
// import { checkWithIdm } from "../function/checkwithidm";
import {checkWithCasbin} from "../function/permissionHandling";
const useStyles = makeStyles((theme) => ({
  media: {
    width: "100%",
    transition: "transform .2s",
    "&:hover": {
      transform: "scale(1.05)",
    },
    [theme.breakpoints.up("lg")]: {
      width: "100%",
      "&:hover": {
        transform: "scale(1.05)",
      },
    },
    [theme.breakpoints.up("xl")]: {
      width: "100%",
      "&:hover": {
        transform: "scale(1.05)",
      },
    },
  },
  card: {
    // width: "445px",
    cursor: "pointer",
  },
  Entertainmenttext: {
    fontSize: "25px",
    color: theme?.palette?.othertextcolor?.blacktext,
    fontFamily: "Poppins_medium",
  },
  heading: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
  },
  icon: {
    color: theme?.palette?.othertextcolor?.blacktext,
  },
  paper: {
    padding: "0.5em 1em",
  },
  listparent: {
    marginTop: "20px",
    // height: "80vh",
    padding:"0px 16px"
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  series: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Entertainment = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [value, setValue] = React.useState(0);
  const [state, seState] = React.useState({});
  const [permission, setPermissiom] = React.useState([]);

  React.useEffect(() => {
    seState(location.state);
  }, [location.state]);
  React.useEffect(() => {
    getPermission();
  }, []);
  async function getPermission() {
    try {
      let permission_ = await checkWithCasbin(["Spe_Application","Entertainment"]);
      setPermissiom(permission_);
    } catch (err) {
      setPermissiom([]);
    }
  }
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className={classes.paper}>
      <Grid container >
        <Grid lg={12} item>
          <div className={classes.heading}>
            <div>
              <IconButton onClick={() => history.goBack()} size="medium">
                <KeyboardBackspaceIcon className={classes.icon} />
              </IconButton>
            </div>
            <Typography className={classes.Entertainmenttext}>
              Entertainment
            </Typography>
          </div>
        </Grid>
        <Grid
          container
          spacing={2}
          className={classes.listparent}
          // justify="space-evenly"
         
        >
          {location?.state?.grps
            ?.filter((v) =>
              // checkWithIdm(
              //   permission[0]?.permission[0]?.permissionRepoAccess ?? [],
              //   "1",
              //   "unique_id",
              //   v?._id,
              //   "read"
              // )
              permission?.write?.indexOf(v.appname) > -1
            )
            .map((v, i) => {
              return (
                <Grid
                  xl={3}
                  lg={3}
                  md={4}
                  sm={6}
                  xs={12}
                  item
                  key={i + "appl"}
                  onClick={() => {
                    window.open(v.url, "_self");
                  }}
                  style={{ display: "flex", placeContent: "center" }}
                >
                  <div className={classes.card}>
                    <img
                      src={config.fileUploadFullUrl + v?.imageid}
                      className={classes.media}
                      alt={v?.appname ?? "appplication"}
                    />
                  </div>
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    </div>
  );
};

export default withNavBars(Entertainment);
