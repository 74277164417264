import React, { useState } from "react";
import {
  AppBar,
  Collapse,
  Grid,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CancelIcon from "@material-ui/icons/Cancel";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { useHistory, useLocation } from "react-router-dom";
import { config } from "../config";
import { Routes } from "../router/routes";

const useStyles = makeStyles((theme) => {
  return {
    iconbutton: {
      position: "absolute",
      left: "48%",
    },
    appbar: {
      backgroundColor: theme.palette.background.paper,
    },
    left: {
      transform: "rotate(180deg)",
      color: theme?.palette?.othertextcolor?.blacktext,
    },
    grpicon: {
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
    },
    iframe: {
      width: "100%",
      height: "100vh",
      outline: "none",
      border: "none",
      scrolling: "auto",
    },
    blackcolor: {
      color: theme?.palette?.othertextcolor?.blacktext,
    },
  };
});
function Iframepage() {
  const history = useHistory();
  const [expanded, setExpanded] = useState(false);
  const [openappbar, setOpenAppbar] = useState(false);
  const [details, setDetails] = useState(config.impourl);
  const [iconColor, setIconColor] = useState("black");

  const classes = useStyles();
  const location = useLocation();

  React.useEffect(() => {
    console.log("deeee", location?.state?.iframe);

    if (Boolean(location?.state?.iframe)) {
      setDetails(location?.state?.iframe);
    } else {
      try {
        let json = btoa(sessionStorage.getItem("tokenDetails"));
        console.log({ json });
        let url = config.impourl;
        let tokenurl = `${url}?token=${json}`;
        console.log(tokenurl);
        setDetails(tokenurl);
      } catch (err) {}
    }
  }, [location?.state]);

  return (
    <div>
      {!openappbar && (
        <IconButton
          onClick={() => {
            setExpanded(!expanded);
            setOpenAppbar(!openappbar);
          }}
          className={classes.iconbutton}
          size="large">
          <ExpandMoreIcon  style={{ color: iconColor }} fontSize="large" />
        </IconButton>
      )}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <AppBar className={classes.appbar} position="absolute">
          <div className={classes.grpicon}>
            <IconButton onClick={() => history.push(Routes.home)}>
              <ArrowRightAltIcon className={classes.left} />
            </IconButton>
            <IconButton
              onClick={() => {
                setExpanded(!expanded);
                setOpenAppbar(!openappbar);
              }}>
              <CancelIcon className={classes.blackcolor} />
            </IconButton>

            <IconButton onClick={() => false} disabled>
              <ArrowRightAltIcon className={classes.blackcolor} />
            </IconButton>
          </div>
        </AppBar>
      </Collapse>
      <Grid container xl={12} lg={12} sm={12} xs={12}>
        <iframe
          allow="camera;microphone"
          title={"impo"}
          src={details}
          className={classes.iframe}
          onLoad={() => setIconColor("white")}
        />
      </Grid>
    </div>
  );
}

export default Iframepage;
