import { Dialog } from "@material-ui/core";
import React, { useEffect } from "react";
import Profiledialog from "./profiledialog";

function Index(props) {

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClose}

        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "10rem",
          borderRadius: "20px",
        }}
      >
        <Profiledialog profdata={props.dialogData}></Profiledialog>
      </Dialog>
    </div>
  );
}

export default Index;
