import React, { useState } from "react";
import {
  Typography,
  AppBar,
  Avatar,
  IconButton,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
/////////////////Svg&Png////////////////////////
import kpjlogo from "../../../assets/kpjlogo.png";
import pic from "../../../assets/dollytanavatar.png";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import jwt_decode from "jwt-decode";
import Profiledialog from "../../profiledialog";
// style
const styles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    opacity: 1,
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    width: 70,
    height: 20,
    padding: "0px 0px 0px 40px",
  },
  divider: {
    width: 2,
    height: 75,
    alignSelf: "center",
    margin: "0px 15px",
    backgroundColor: "#F3F5F7f",
  },
  logo: {
    marginRight: "10px",
  },

  vector: {
    width: 10,
    height: 15,
    cursor: "pointer",
  },
  notificationicon: {
    width: 22,
    height: 28,
  },
  mic: {
    width: 42,
    height: 42,
  },
  alert: {
    width: 30,
    height: 30,
  },
  container: {},
  appbar: {
    width: "100%",
    backgroundColor: theme?.palette?.background?.paper,
    boxShadow: "none",
    padding: "0.3em 1em",
    borderBottom: `5px solid ${theme?.palette?.background?.downborder_color_and_cardcarousels_inside_card}`,
    // height: "100%",
  },

  logoutDiv: {
    "& .MuiAvatar-root": {
      width: 20,
      height: 20,
    },
  },
  Heading: {
    fontSize: 25,
    color: theme?.palette?.othertextcolor?.blacktext,
    padding: "0px 0px 0px 20px",
  },
  rakin: {
    fontSize: 18,
    color: theme?.palette?.othertextcolor?.blacktext,
    fontstyle: "bold",
  },
  value: {
    "&::first-letter": {
      color: theme?.palette?.othertextcolor?.blacktext,
    },
    fontSize: 18,
    color: "red",
    fontstyle: "bold",
  },
  emergencytxt: {
    fontSize: 20,
    color: "#FA273E",
  },
  padding: {
    padding: " 0px 10px 0px 40px",
  },
  logotext: {
    color: theme?.palette?.othertextcolor?.logo,
    fontFamily: "Copperplate_Gothic_bold",
    fontSize: "25px",
  },
  logoandtitle: {
    // height: "100%",
    display: "inline-flex",
    alignItems: "center",
    padding: " 0px 10px 0px 20px",
  },
  innerappbardiv: {
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    // height: "100%",
  },
  usertext: {
    color: theme?.palette?.othertextcolor?.blacktext,
    fontFamily: "Poppins_medium",
  },
  avatar: {
    marginRight: "5px",
  },
  list: {
    "& .MuiListItemText-root": {
      cursor: "pointer",
    },
  },
  icon: {
    color: "black",
  },
  header: {
    display: "inline-flex",
    alignItems: "center",
  },
}));

function Header() {
  const classes = styles();
  const Theme = useTheme();
  const sm = useMediaQuery(Theme?.breakpoints?.down("sm"));
  const [state, setState] = useState({});
  const [close, setclose] = useState(false);
  React.useEffect(() => {
    let tokendetails = sessionStorage.getItem("access_token");
    tokendetails = jwt_decode(tokendetails);
    setState(tokendetails);
  }, []);
  const handleClose = () => {
    setclose(false);
  };

  // console.log({ state });
  const values = {
    title: window.sessionStorage.getItem("roll_name"),
    name: state?.name??"Unknown",
    email: state?.email??"Unknown",
    image: pic,
    bedNo: "15",
    roomNo: "18",
  };
  return (
    <div className={classes.root}>
      {/* AppBar Component in Top navar */}
      <AppBar position="static" className={classes.appbar}>
        <div className={classes.innerappbardiv}>
          <div className={classes.logoandtitle}>
            <img
              alt="kpjlogo"
              //src={kpjlogo}
              src = "images/ainqa logo.png"
              className={classes.logo}
              width="50"
              height="50"
            />
            <Typography variant="h6" className={classes.logotext}>
              {sm ? "SPE" : "Smart Patient Engagement"}
            </Typography>
          </div>
          <div className={classes.header}>
            <Avatar alt={state?.name ?? "Anonymous"} className={classes.avatar}>
              {state?.name?.[0] ?? ""}
            </Avatar>
            <Typography className={classes.usertext}>
              {state?.name ?? "user"}
            </Typography>
            <IconButton
              size="small"
              onClick={(e) => {
                setclose(true);
              }}
            >
              <ExpandMoreIcon className={classes.icon} />
            </IconButton>
            <Profiledialog
              open={close}
              onClose={handleClose}
              dialogData={values}
            />
          </div>
        </div>
      </AppBar>

      {/* end */}
    </div>
  );
}

export default Header;
