import React from "react";
import { makeStyles } from "@material-ui/core";
import { TopNavBar, SideNavBar } from "../components";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme?.palette?.background?.paper,
    minHeight: "100vh",
  },
  content: {
    width: "100%",
    height: "calc(100% - 10%)",
    // display: "flex",
    // alignItems: "center",
    overflow: "auto",
  },
  topNavbar: {
    // height: "10%",
  },
  sideNavbar: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const withNavBars = (Component) => (props) => {
  const classes = useStyles({ props });

  return (
    <div className={classes.root} id="rootbelow">
      {/* Your nav bars here */}
      <div className={classes.topNavbar}>
        <TopNavBar />
      </div>

      {/* {<div className={classes.sideNavbar}>
        <SideNavBar />
      </div>} */}

      {/* Content */}
      <div className={classes.content}>
        <Component {...props}>{props.children}</Component>
      </div>
    </div>
  );
};

export default withNavBars;
