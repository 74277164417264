import React from "react";
import AppAuth from "./App.auth";
import AppTheme from "./App.theme";
import AppAlert from "./App.alert";
import AppErrorBoundary from "./App.errorBoundry";
import RouterApp from "./router";
// import ApolloClient from "./App.gqlclient";
import AppDrawer from "./App.drawer";
import { CssBaseline } from "@material-ui/core";
import AppDialog from "./App.dialog";
import AppBackdrop from "./App.backdrop";
import store from "./redux";
import { Provider } from "react-redux";

const App = () => {
  return (
    <Provider store={store}>
      <AppErrorBoundary>
        <AppAuth>
          <AppTheme>
            <CssBaseline />
            <AppAlert>
              <AppBackdrop>
                <AppDialog>
                  <AppDrawer>
                    <RouterApp />
                  </AppDrawer>
                </AppDialog>
              </AppBackdrop>
            </AppAlert>
          </AppTheme>
        </AppAuth>
      </AppErrorBoundary>
    </Provider>
  );
};
export default App;
