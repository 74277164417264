import {
  Button,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";
import { config } from "../../config";
import Carousel from "react-material-ui-carousel";
import PropTypes from "prop-types";
const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 2000,
};
const useStyles = makeStyles((theme) => ({
  root: {
    // "& .MuiButton-containedSizeLarge": {
    //   // padding: "8px 45px",
    //   backgroundColor: theme?.palette?.background?.paper,
    //   borderRadius: "8px",
    //   color: "#277FFE",
    //   fontSize: "16px",
    //   textTransform: "initial",
    // },
    // "& .slick-dots li.slick-active button:before": {
    //   color: "#277FFE",
    // },
    // "& .slick-dots li button:before": {
    //   fontSize: "10px",
    //   lineHeight: "30px",
    // },
  },




  cardcontent: {
    padding: "30px 0px 20px 0px",
  },
}));

function Carousels({ json = [] }) {
  const theme = useTheme();
  const xl = useMediaQuery(theme?.breakpoints.only("xl"));
  const classes = useStyles();
  return (
    <Grid container spacing={1} className={classes?.root}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Carousel navButtonsAlwaysInvisible={true}>
          {(Array.isArray(json) ? json : []).map((v, i) => {
            return (
              <Grid container index={i} key={`carousel${i}`}>
                <Card
                  style={{
                    backgroundImage: `url(${config.fileUploadFullUrl + v?.imgid
                      })`,
                    backgroundPosition: "right center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    borderRadius: "15px",
                    height: "37vh",
                    width: "100%",
                    padding: xl ? "30px" : "30px",
                    boxShadow:"none"
                  }}
                >
                  <Grid
                    item
                    lg={v?.muiresponsivelengthoftext?.lg ?? 12}
                    md={v?.muiresponsivelengthoftext?.md ?? 12}
                    sm={v?.muiresponsivelengthoftext?.sm ?? 12}
                    xs={v?.muiresponsivelengthoftext?.xs ?? 12}
                    xl={v?.muiresponsivelengthoftext?.xl ?? 12}
                  >
                    <CardContent className={classes.cardcontent}>
                      <Typography
                        variant="h4"
                        style={{
                          color: v?.color,
                          fontWeight: v?.fontWeight,
                          fontSize: v?.fontSize,
                          letterSpacing: v?.letterSpacing ?? "",
                          wordSpacing: v?.wordSpacing ?? ",",
                          fontFamily: "Poppins_bold",
                        }}
                      >
                        {v?.displayText}
                      </Typography>
                    </CardContent>
                  </Grid>
                  <Grid item style={{}}>
                    <Button
                      // size={xl ? "large" : "small"}
                      size="medium"
                      variant="contained"
                      style={{
                        backgroundColor: v?.buttonColor,
                        position: "absolute !important",
                        zIndex: 2,
                        width:"150px"
                      }}
                      onClick={() =>
                        window.open(v?.advertisementlink, "_blank")
                      }
                    >
                      <a
                        rel="noreferrer"
                        href={v?.advertisementlink}
                        target="blank"
                        style={{
                          textDecoration: "none",
                          cursor: "pointer",
                          color: v?.buttonTextColor,
                        }}
                      >
                        {v?.buttonName}
                      </a>
                    </Button>
                  </Grid>
                </Card>
              </Grid>
            );
          })}
        </Carousel>
      </Grid>
    </Grid>
  );
}

Carousel.defaultProps = {
  json: [],
};
Carousel.propTypes = {
  json: PropTypes.array.isRequired,
};

export default Carousels;
